
$(function() {

    if ( $('.contact-table').length > 0 ) {

        var maps = Array(
                {map:"QC", lat:45.754005, lng:-73.988264},
                {map:"ON", lat:43.805709, lng:-79.5297617},
                {map:"BC", lat:49.249283, lng:-122.9513197},
                {map:"US", lat:45.754005, lng:-73.988264}
            ),
            map_zoom = 12;

        // Google map custom marker icon - .png fallback for IE11
        var is_internetExplorer11= navigator.userAgent.toLowerCase().indexOf('trident') > -1;
        var marker_url = '/images/riobelpro-2017/map-marker.png';

        // We define here the style of the map
        var styleArray = [
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#444444"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#46bcec"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            }
        ];

        for(var i = 0; i < maps.length; i++) {

            var _lat = maps[i].lat,
                _lng = maps[i].lng,
                _map = maps[i].map;

            // Set google map options
            var map_options = {
                center: new google.maps.LatLng(_lat, _lng),
                zoom: map_zoom,
                panControl: false,
                zoomControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scrollwheel: false,
                styles: styleArray,
                draggable: false
            };

            // Inizialize the map
            var map = new google.maps.Map(document.getElementById(_map), map_options);

            var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(_lat, _lng),
                    map: map,
                    visible: true,
                    icon: marker_url,
                    id: i
                });

        }

        $(".contact-place-dropdown").slideUp(400);

    }

});