
$(function() {


    $('.js-inch-btn').click(function(e){

        e.preventDefault();

        var $val = $(this).data('inch');
        $(this).addClass('checked').siblings('.checked:not(this)').removeClass('checked');
        $('.js-get-inch').val($val);

        if ( $val == 12 ) {

            $('.finish-holder.BK').hide();

            if ( $('#finish7').is(':checked') ) {

                $('#finish1').click();
            }
        }
        else {

            $('.finish-holder.BK').show();
        }
    });

    $('.js-spout, .js-handle, .js-finish').click(function(e){

        var _holders = $(this).closest('.one-third').find('.input-holder'),
            _holder = $(this).closest('.input-holder'),
            _input = _holder.find('input[type=radio]');

        _holders.removeClass('checked');
        _holder.addClass('checked');
    });

    $('.form-submit').click(function() {

        var _form = $(this).closest('form'),
            _error = _form.find('.make-faucet-error');

        if ( !$('.spout-holder.checked').length || !$('.handle-holder.checked').length || !$('.finish-holder.checked').length ) {

            _error.slideDown();
        }
        else {

            _error.slideUp();
            _form.submit();
        }
    });

});