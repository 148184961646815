
$(function() {

    var _model3D = $('.model3D');
    
    if ( _model3D.length > 0 ) {


        $('.product-color').click(function() {

            var sketchfab = $(this).data('sketchfab');
            var target = $(this).data('target');

            // var text = ( sketchfab == '' ) ? 'MODEL_NOT_AVAILABLE' : 'MODEL_AVAILABLE';

            // _model3D.data('target', target).html( text );
        });
        
        _model3D.click(function(event) {

            event.preventDefault();

            var url = $(this).data('target');

            PopupCenter(url, 640, 480);

        });

        function PopupCenter(url, w, h) {

            // Fixes dual-screen position                         Most browsers      Firefox
            var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
            var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

            var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

            var left = ((width / 2) - (w / 2)) + dualScreenLeft;
            var top = ((height / 2) - (h / 2)) + dualScreenTop;
            var newWindow = window.open(url, 'Riobel', 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

            // Puts focus on the newWindow
            if (window.focus) {

                newWindow.focus();
            }
        }
    }
    
});