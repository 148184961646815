
$(function() {

    if ( $('.product-item').length > 0 ) {

        var lightbox = lity();
    
        $('.product-background').click(function () {
        
            var _image = $(this).attr('data-active');
        
            lightbox(_image);
        
        });

        $('.product-background-click').click(function() {

            var _image = $(this).attr('data-active');

            lightbox(_image);

        });

    }

});
