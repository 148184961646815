
// Namespacing

window.Riobel = window.Riobel || {};


// Launch modules

(function ($) {

    'use strict';

    var app = this;


    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

}.bind(Riobel, jQuery)());

$(document).ready(function() {
    $('.main-carousel').flickity({
      cellAlign: 'left',
      contain: true,
      imagesLoaded: true
    });
});