
$(function() {
  if ( $('.product-color').length > 0 ) {
    const $productsColor = $('.product-color');

    console.log($productsColor);

    $productsColor.on('click', function (i){

      let dataImageHd = $(this).attr('data-image-hd');

      $('.product-color').removeClass('active');

      $(this).addClass('active');

      console.log('toto', dataImageHd );

      $('.product-background').addClass('is-none');

      $('.product-background-click').addClass('is-active');

      $('.product-background-click').css('background-image', 'url(' + dataImageHd + ')');
  
      $('.product-background-click').attr('data-active', dataImageHd);

      console.log('background-image', "'url("+dataImageHd+")'");

    });
  };
});
