
$(function() {

    if ( $('.product-item .product-color').length > 0 ) {

        var default_img = $('.product-background').data('active'),
            array_images = [];

        $('.product-item .product-color').each(function() {

            if ( $(this).data('image-hd') == default_img ) {

                $(this).addClass('active');
            } else {

                array_images.push( $(this).data('image-hd') );
            }

        });

        function preload(arrayOfImages) {
            $(arrayOfImages).each(function(){
                $('<img/>')[0].src = this;
                // Alternatively you could use:
                // (new Image()).src = this;
            });
        }

        preload( array_images );

        $('.product-color').click(function() {

            // If the color is available soon
            if ( $(this).data('availability-soon') == 1) {
                // Hide the "New product"
                $('.new').hide();
                // Show the "Soon"
                $('.soon').show();
            }
            else {
                // Show the "New product"
                $('.new').show();
                // Hide the "Soon"
                $('.soon').hide();
            }

            // if ( !$(this).hasClass('active') && $(this).data('image-hd').length > 0 ) {

            //     $('.product-color.active').removeClass('active');
            //     $(this).addClass('active');

            //     $('.product-background')
            //         .css('background-image', 'url(' + $(this).data('image-hd') + ')')
            //         .attr('data-active', $(this).data('image-hd'));

            // }
            
        });
        
    }

});